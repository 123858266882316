import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import logger, { LogTagKey } from "../../utils/logger";
import useApi from "../useApi";
import { useTranslation } from "react-i18next";
import { useValidateEmail } from "../useValidateEmail";
import { useSignUpContext } from "../../context/SignUpContext";
import { mixPanelInstance } from "../../utils/analytics/mixpanel";
import { UserInfo } from "../../components/signup/SignUpApi";
import { AuthApi, IAuthResponse } from "../../components/auth/AuthApi";
import { useUTMQuery } from "../useUTMQuery";
import { SIGN_UP_V2_INDEX_ROUTE_STEP_NO, SIGN_UP_V2_PHONE_NUMBER_URL } from "../../components/signup/SignUpV2";
import { isCountryUk } from "../../utils/country";
import { useUtmsContext } from "../../context/UTMSContext";
import { useTrackTwitter } from "../useTrackTwitter";
import { EAggregators } from "utils/aggregators";
import { useQuery } from "hooks/useQuery";

interface ISignUpEmail {
  email: string;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => Promise<void>;
  errorMessage: string | null;
  state: number;
  isLoading: boolean;
  validated: boolean;
  isValidForm: boolean;
  emailRef: any;
  hasTypo: boolean;
  aggregatorUid?: string;
}

enum ESignUpContactInfoState {
  NOT_SENT = 0,
  INVALID_CONTACT_DETAILS = 1,
  VALID_CONTACT_DETAILS = 2,
  INVALID_VERIFICATION_CODE = 3,
  CONTACT_DETAILS_SUBMITTED = 4,
  VALID_VERIFICATION_CODE = 5,
}

const EMAIL_ADDRESS_IN_USE_ERROR = "User is already registered with your email address.";

//shared hook
export const useSignUpEmail = (setSignUpStep: any, aggregatorName?: EAggregators | undefined): ISignUpEmail => {
  useUTMQuery();
  const { trackTwitterPageView } = useTrackTwitter();
  const { buildReferral } = useUtmsContext();
  const emailRef: any = useRef();
  const { auth, signUp } = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo, setUserInfo, signUpUUID, setSignUpSessionId, setAggregator } = useSignUpContext();
  const [email, setEmail] = useState<string>(userInfo?.email ?? "");
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [state, setState] = useState<number>(ESignUpContactInfoState.NOT_SENT);
  const [validated, setValidated] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const getIsAggregatorCustomer = () => {
    return [
      EAggregators.CLEARSCORE,
      EAggregators.CLEARSCORE_STORE_CARD,
      EAggregators.CREDIT_KARMA,
      EAggregators.CREDIT_KARMA_STORE_CARD,
      EAggregators.TOTALLY_MONEY,
      EAggregators.TOTALLY_MONEY_STORE_CARD,
      EAggregators.MONEY_SUPERMARKET,
      EAggregators.VANQUIS,
    ].includes(aggregatorName as any);
  };
  const aggregatorUid = useQuery().get("uid") ?? undefined;

  const { hasTypo } = useValidateEmail(email);

  useEffect(() => {
    if (userInfo?.email !== "") {
      validateContactDetails(userInfo.email!);
    }
    emailRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (hasTypo) {
      setIsValidForm(false);
      return emailRef?.current?.setCustomValidity("Invalid field.");
    }
    emailRef?.current?.setCustomValidity("");
  }, [email, hasTypo]);

  const trackPageView = (signUpUUID: string | undefined) => {
    if (!getIsAggregatorCustomer()) {
      mixPanelInstance.trackSignUpEmail(signUpUUID);
    }
  };

  useEffect(() => {
    setAggregator(aggregatorName);
    trackTwitterPageView();
    trackPageView(signUpUUID);
    setSignUpStep(SIGN_UP_V2_INDEX_ROUTE_STEP_NO);
    setUserInfo({ ...userInfo, userId: uuidv4() });
    return () => setSignUpStep(0);
  }, []);

  const regex = /^[A-Z0-9.+_-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const contactDetailsSchema: yup.ObjectSchema<any> = yup.object().shape({
    email: yup.string().email().required().matches(regex),
    hasTypo: yup.boolean().isFalse(),
  });

  const validateContactDetails = async (email: string): Promise<void> => {
    setErrorMessage(null);

    await contactDetailsSchema
      .validate({ email })
      .then(() => {
        setState(ESignUpContactInfoState.VALID_CONTACT_DETAILS);
        setIsValidForm(true);
      })
      .catch(() => {
        setIsValidForm(false);
        setState(ESignUpContactInfoState.INVALID_CONTACT_DETAILS);
      })
      .finally(() => setValidated(true));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setEmail(value);
    validateContactDetails(value);
  };

  const handleVerifyEmailInUse = async (): Promise<string | undefined> => {
    setIsloading(true);
    const signUpSessionId: string | undefined = await registerUserSession();
    setIsloading(false);
    if (!signUpSessionId) {
      return undefined;
    }
    setIsValidForm(true);
    setSignUpSessionId(signUpSessionId);
    return signUpSessionId;
  };

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setSubmitted(true);
    const signUpSessionId = await handleVerifyEmailInUse();
    if (signUpSessionId && isValidForm) {
      const referral = buildReferral();
      setUserInfo({ ...userInfo, email, referral });
      setIsloading(false);
      navigate(SIGN_UP_V2_PHONE_NUMBER_URL);
    }
  };

  const buildRequestData = (userInfo: UserInfo): UserInfo => {
    const { address2, city, dateBirth, referredBy } = userInfo;
    const isAggregatorCustomer = getIsAggregatorCustomer();
    return {
      address1: "address 1 placeholder",
      address2,
      address3: "",
      city,
      dateBirth: dateBirth !== "" ? dateBirth : "2000-01-01",
      email,
      firstName: "Firstname placeholder",
      lastName: "Lastname placeholder",
      postCode: "111111",
      referredBy,
      aggregator: aggregatorName,
      aggregatorUid: isAggregatorCustomer ? aggregatorUid ?? uuidv4() : undefined,
      isAggregatorCustomer,
    };
  };

  const validateRegisterEmailInUseError = async (response: any): Promise<boolean> => {
    if (response?.code === 400 && Array.isArray(response.detail) && response.detail[0] === EMAIL_ADDRESS_IN_USE_ERROR) {
      const response: IAuthResponse = await AuthApi.resetPassword(email);
      if (response?.code) {
        setErrorMessage(t("auth.errors.registration"));
        logger.error(response?.message, LogTagKey.TkSignup, "Reset user password failed");
        throw new Error(response?.message || "Error while resetting your password");
      } else {
        setErrorMessage(t("auth.forms.createAccount.passwordReset"));
        return true;
      }
    }
    setIsValidForm(false);
    return false;
  };

  const saveEmailToMaichimp = async () => {
    if (isCountryUk()) {
      await signUp.saveEmailInMailchimpList(email!);
    }
  };

  const registerUserSession = async (): Promise<string | undefined> => {
    try {
      const response: any = await auth.registerSession(buildRequestData(userInfo));
      if (!response?.sessionId) {
        await validateRegisterEmailInUseError(response);
        throw new Error("Error registering user session");
      }
      await saveEmailToMaichimp();
      return response.sessionId;
    } catch (err: any) {
      const isEmailInUseError = await validateRegisterEmailInUseError(err.error);
      if (isEmailInUseError) {
        logger.info("E-mail already in use", LogTagKey.TkSignup, "E-mail already in use");
      } else {
        logger.error(err, LogTagKey.TkSignup, "Register user session failed");
      }
      return undefined;
    }
  };

  return {
    email,
    handleEmailChange,
    validated: validated && submitted,
    isLoading,
    handleSubmit,
    errorMessage,
    state,
    isValidForm,
    emailRef,
    hasTypo,
    aggregatorUid,
  };
};
