import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const IMPORTANT_INFO_SCENARIOS = [
  {
    title: "common.importantInfo.whomFor.personA.title",
    subtitle: "common.importantInfo.whomFor.personA.subtitle",
    paragraph: "common.importantInfo.whomFor.personA.paragraph",
  },
  {
    title: "common.importantInfo.whomFor.personB.title",
    subtitle: "common.importantInfo.whomFor.personB.subtitle",
    paragraph: "common.importantInfo.whomFor.personB.paragraph",
  },
  {
    title: "common.importantInfo.whomFor.personC.title",
    subtitle: "common.importantInfo.whomFor.personC.subtitle",
    paragraph: "common.importantInfo.whomFor.personC.paragraph",
  },
  {
    title: "common.importantInfo.whomFor.personD.title",
    subtitle: "common.importantInfo.whomFor.personD.subtitle",
    paragraph: "common.importantInfo.whomFor.personD.paragraph",
  },
  {
    title: "common.importantInfo.whomFor.personE.title",
    subtitle: "common.importantInfo.whomFor.personE.subtitle",
    paragraph: "common.importantInfo.whomFor.personE.paragraph",
  },
];

const renderScenario = (t: any, scenario: any, index: number) => {
  return (
    <div key={`important_info_${index}`} className="p-2">
      <p className="font-weight-bold">{t(scenario.title)}</p>
      <p>{t(scenario.subtitle)}</p>
      <p>{t(scenario.paragraph)}</p>
    </div>
  );
};
export const renderImportantInformationScenarios = (t: any) => {
  return IMPORTANT_INFO_SCENARIOS.map((scenario: any, index: number) => renderScenario(t, scenario, index));
};

export const ImportantInformationModal = ({
  handleImportantInfoClick,
  show,
  setShow,
}: {
  handleImportantInfoClick: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
}) => {
  const { t } = useTranslation();

  const renderImportantInfo = () => {
    return (
      <>
        <Button className="important-info-link" variant="link" onClick={handleImportantInfoClick}>
          {t("common.importantInfo.title")}
        </Button>

        <Modal className="important-info-modal" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("common.importantInfo.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="font-weight-bold">{t("common.importantInfo.subTitle")}</p>
            <ul>
              <li>
                <p>{t("common.importantInfo.description.one")}</p>
              </li>
              <li>
                <p>{t("common.importantInfo.description.two")}</p>
              </li>
              <li>
                <p>{t("common.importantInfo.description.three")}</p>
              </li>
              <li>
                <p>{t("common.importantInfo.description.four")}</p>
              </li>
            </ul>
            <p className="font-weight-bold">{t("common.importantInfo.whomFor.title")}</p>
            {renderImportantInformationScenarios(t)}
            <p className="font-weight-bold">{t("common.importantInfo.disclaimer")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mx-auto" variant="secondary" onClick={() => setShow(false)}>
              {t("common.importantInfo.ok")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  return renderImportantInfo();
};
