import mixpanel from "mixpanel-browser";
import { PaymentMethod } from "../../components/checkout/Checkout";
import { SubscriptionPlan } from "../../components/signup/SignUpApi";

export enum MixpanelTrackEventType {
  SIGNUP_PAYMENT = "SIGNUP_PAYMENT",
  SIGNUP_EMAIL = "SIGNUP_EMAIL",
  SIGNUP_PHONE = "SIGNUP_PHONE",
  SIGNUP_VERIFY_PHONE = "SIGNUP_VERIFY_PHONE",
  SIGNUP_LEGAL_NAME = "SIGNUP_LEGAL_NAME",
  SIGNUP_ADDRESS = "SIGNUP_ADDRESS",
  SIGNUP_CONTACT_INFO = "SIGNUP_CONTACT_INFO",
  SIGNUP_CREATE_PASSWORD = "SIGNUP_CREATE_PASSWORD",
  SIGNUP_REFERRALS = "SIGNUP_REFERRALS",
  SIGNUP_IMPORTANT_INFORMATION_PAGE = "SIGNUP_IMPORTANT_INFORMATION_PAGE",
  SIGNUP_ABOUT_YOUR_DATA = "SIGNUP_ABOUT_YOUR_DATA",
  SIGNUP_SELECT_SUBSCRIPTION = "SIGNUP_SELECT_SUBSCRIPTION",
  PAID_BY_DEBIT = "PAID_BY_DEBIT",
  PAID_BY_CREDIT = "PAID_BY_CREDIT",
  SIGNUP_SELECT_SUBSCRIPTION_PLAN = "SIGNUP_SELECT_SUBSCRIPTION_PLAN",
  SIGNUP_PURCHASE_SUBSCRIPTION = "SIGNUP_PURCHASE_SUBSCRIPTION",
  SIGNUP_TIKTOK_SUBSCRIPTION_PURCHASED = "SIGNUP_TIKTOK_SUBSCRIPTION_PURCHASED",
  SIGNUP_FACEBOOK_SUBSCRIPTION_PURCHASED = "SIGNUP_FACEBOOK_SUBSCRIPTION_PURCHASED",
  SIGNUP_EMAIL_SUBSCRIPTION_PURCHASED = "SIGNUP_EMAIL_SUBSCRIPTION_PURCHASED",
  SIGNUP_EMAIL_STORE_PURCHASE = "SIGNUP_EMAIL_STORE_PURCHASE",
  SIGNUP_TIKTOK_STORE_PURCHASE = "SIGNUP_TIKTOK_STORE_PURCHASE",
  SIGNUP_FACEBOOK_STORE_PURCHASE = "SIGNUP_FACEBOOK_STORE_PURCHASE",
  SIGNUP_WITH_DISCOUNT_SUCCESS = "SIGNUP_WITH_DISCOUNT_SUCCESS",
  SIGNUP_IMPORTANT_INFORMATION_LINK = "SIGNUP_IMPORTANT_INFORMATION_LINK",
  AGGREGATOR_INIT = "AGGREGATOR_INIT",
  SIGNUP_TOTALLY_MONEY = "SIGNUP_TOTALLY_MONEY",
  SIGNUP_CREDIT_KARMA = "SIGNUP_CREDIT_KARMA",
  SIGNUP_CLEARSCORE = "SIGNUP_CLEARSCORE",
  SIGNUP_TOTALLY_MONEY_STORE_CARD = "SIGNUP_TOTALLY_MONEY_STORE_CARD",
  SIGNUP_CREDIT_KARMA_STORE_CARD = "SIGNUP_CREDIT_KARMA_STORE_CARD",
  SIGNUP_CLEARSCORE_STORE_CARD = "SIGNUP_CLEARSCORE_STORE_CARD",
  SIGNUP_MONEY_SUPERMARKET = "SIGNUP_MONEY_SUPERMARKET",
  SIGNUP_VANQUIS = "SIGNUP_VANQUIS",
}
export interface MixPanelSignUpStatus {
  SIGNUP_LEGAL_NAME?: string;
  SIGNUP_ADDRESS?: string;
  SIGNUP_CONTACT_INFO?: string;
  SIGNUP_CREATE_PASSWORD?: string;
  SIGNUP_REFERRALS?: string;
  SIGNUP_IMPORTANT_INFORMATION_PAGE?: string;
  SIGNUP_ABOUT_YOUR_DATA?: string;
  SIGNUP_SELECT_SUBSCRIPTION?: string;
}

const BITS_PAY_NOW_PURCHASE = "PAY_NOW_BY_DEBIT";
const BITS_MIXPANEL_STATUS = "bits-mixpanel-signup-status";
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || "";
class MixPanel {
  initialized = false;
  isDisabled = mixpanelToken === undefined || mixpanelToken === "";
  constructor() {
    this.initMixpanel();
  }

  private initMixpanel() {
    if (this.initialized || this.isDisabled) {
      return;
    }
    mixpanel.init(mixpanelToken, { ignore_dnt: true });
    this.saveMixPanelStatus({});
    this.initialized = true;
  }

  private trackSignUp(event: any, signUpUUID: string | null = null) {
    this.track(event, null);
    if (signUpUUID && event) {
      const mixPanelSignUpStatus: any = this.getMixPanelStatus();
      mixPanelSignUpStatus[event] = signUpUUID;
      this.saveMixPanelStatus(mixPanelSignUpStatus);
    }
  }

  private track(event: any, data: any = null) {
    if (!this.initialized || this.isDisabled) {
      return;
    }
    mixpanel.track(event, data);
  }

  private saveMixPanelStatus(status: MixPanelSignUpStatus) {
    localStorage.setItem(BITS_MIXPANEL_STATUS, JSON.stringify(status));
  }

  private getMixPanelStatus(): MixPanelSignUpStatus {
    return JSON.parse(localStorage.getItem(BITS_MIXPANEL_STATUS) ?? "{}");
  }

  trackSignUpLegalName(signUpUUID: string | undefined) {
    if (this.shouldEventBeTracked(MixpanelTrackEventType.SIGNUP_LEGAL_NAME, signUpUUID)) {
      this.trackSignUp(MixpanelTrackEventType.SIGNUP_LEGAL_NAME, signUpUUID);
    }
  }

  private shouldEventBeTracked(event: any, value: string | undefined) {
    if (!this.initialized || this.isDisabled || !value) {
      return false;
    }
    const mixPanelSignUpStatus: any = this.getMixPanelStatus();
    return mixPanelSignUpStatus[event] !== value;
  }

  trackSignUpAddress(signUpUUID: string | undefined) {
    const { SIGNUP_ADDRESS } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_ADDRESS, signUpUUID)) {
      this.trackSignUp(SIGNUP_ADDRESS, signUpUUID);
    }
  }

  trackSignUpEmail(signUpUUID: string | undefined) {
    const { SIGNUP_EMAIL } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_EMAIL, signUpUUID)) {
      this.trackSignUp(SIGNUP_EMAIL, signUpUUID);
    }
  }

  trackTotallyMoneyStoreCard(signUpUUID: string | undefined) {
    const { SIGNUP_TOTALLY_MONEY } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_TOTALLY_MONEY, signUpUUID)) {
      this.trackSignUp(SIGNUP_TOTALLY_MONEY, signUpUUID);
    }
  }

  trackTotallyMoney(signUpUUID: string | undefined) {
    const { SIGNUP_TOTALLY_MONEY_STORE_CARD } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_TOTALLY_MONEY_STORE_CARD, signUpUUID)) {
      this.trackSignUp(SIGNUP_TOTALLY_MONEY_STORE_CARD, signUpUUID);
    }
  }

  trackClearscoreStoreCard(signUpUUID: string | undefined) {
    const { SIGNUP_CLEARSCORE_STORE_CARD } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_CLEARSCORE_STORE_CARD, signUpUUID)) {
      this.trackSignUp(SIGNUP_CLEARSCORE_STORE_CARD, signUpUUID);
    }
  }

  trackClearscore(signUpUUID: string | undefined) {
    const { SIGNUP_CLEARSCORE } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_CLEARSCORE, signUpUUID)) {
      this.trackSignUp(SIGNUP_CLEARSCORE, signUpUUID);
    }
  }

  trackCreditKarmaStoreCard(signUpUUID: string | undefined) {
    const { SIGNUP_CREDIT_KARMA_STORE_CARD } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_CREDIT_KARMA_STORE_CARD, signUpUUID)) {
      this.trackSignUp(SIGNUP_CREDIT_KARMA_STORE_CARD, signUpUUID);
    }
  }
  trackCreditKarma(signUpUUID: string | undefined) {
    const { SIGNUP_CREDIT_KARMA } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_CREDIT_KARMA, signUpUUID)) {
      this.trackSignUp(SIGNUP_CREDIT_KARMA, signUpUUID);
    }
  }

  trackVanquis(signUpUUID: string | undefined) {
    const { SIGNUP_VANQUIS } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_VANQUIS, signUpUUID)) {
      this.trackSignUp(SIGNUP_VANQUIS, signUpUUID);
    }
  }

  trackMoneySupermarket(signUpUUID: string | undefined) {
    const { SIGNUP_MONEY_SUPERMARKET } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_MONEY_SUPERMARKET, signUpUUID)) {
      this.trackSignUp(SIGNUP_MONEY_SUPERMARKET, signUpUUID);
    }
  }

  trackSignUpPhone(signUpUUID: string | undefined) {
    const { SIGNUP_PHONE } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_PHONE, signUpUUID)) {
      this.trackSignUp(SIGNUP_PHONE, signUpUUID);
    }
  }

  trackSignUpVerifyPhone(signUpUUID: string | undefined) {
    const { SIGNUP_VERIFY_PHONE } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_VERIFY_PHONE, signUpUUID)) {
      this.trackSignUp(SIGNUP_VERIFY_PHONE, signUpUUID);
    }
  }

  trackSignUpPayment(signUpUUID: string | undefined) {
    const { SIGNUP_PAYMENT } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_PAYMENT, signUpUUID)) {
      this.trackSignUp(SIGNUP_PAYMENT, signUpUUID);
    }
  }

  trackSignUpContactInfo(signUpUUID: string | undefined) {
    const { SIGNUP_CONTACT_INFO } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_CONTACT_INFO, signUpUUID)) {
      this.trackSignUp(SIGNUP_CONTACT_INFO, signUpUUID);
    }
  }

  trackSignUpCreatePassword(signUpUUID: string | undefined) {
    const { SIGNUP_CREATE_PASSWORD } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_CREATE_PASSWORD, signUpUUID)) {
      this.trackSignUp(SIGNUP_CREATE_PASSWORD, signUpUUID);
    }
  }

  trackSignUpReferrals(signUpUUID: string | undefined) {
    const { SIGNUP_REFERRALS } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_REFERRALS, signUpUUID)) {
      this.trackSignUp(SIGNUP_REFERRALS, signUpUUID);
    }
  }

  trackImportantInformationPage(signUpUUID: string | undefined) {
    const { SIGNUP_IMPORTANT_INFORMATION_PAGE } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_IMPORTANT_INFORMATION_PAGE, signUpUUID)) {
      this.trackSignUp(SIGNUP_IMPORTANT_INFORMATION_PAGE, signUpUUID);
    }
  }

  trackSignUpAboutYourData(signUpUUID: string | undefined) {
    const { SIGNUP_ABOUT_YOUR_DATA } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_ABOUT_YOUR_DATA, signUpUUID)) {
      this.trackSignUp(SIGNUP_ABOUT_YOUR_DATA, signUpUUID);
    }
  }

  trackSignUpSelectSubscription(signUpUUID: string | undefined) {
    const { SIGNUP_SELECT_SUBSCRIPTION } = MixpanelTrackEventType;
    if (this.shouldEventBeTracked(SIGNUP_SELECT_SUBSCRIPTION, signUpUUID)) {
      this.trackSignUp(SIGNUP_SELECT_SUBSCRIPTION, signUpUUID);
    }
  }

  trackSubscriptionPlanSelected(plan: SubscriptionPlan) {
    const data = {
      Amount: plan.amount.toFixed(2),
      PlanID: plan.subscriptionPlanId,
      Title: plan.title,
    };
    if (!this.initialized || this.isDisabled) {
      return;
    }
    mixpanel.track(MixpanelTrackEventType.SIGNUP_SELECT_SUBSCRIPTION_PLAN, data);
  }

  trackSubscriptionPurchased(plan: SubscriptionPlan) {
    const data = {
      Amount: plan.amount.toFixed(2),
      PlanID: plan.subscriptionPlanId,
      Title: plan.title,
    };
    if (!this.initialized || this.isDisabled) {
      return;
    }
    mixpanel.track(MixpanelTrackEventType.SIGNUP_PURCHASE_SUBSCRIPTION, data);
  }

  trackPurchase(mode: PaymentMethod, checkoutId: string, utmSource = "") {
    const event =
      mode === PaymentMethod.Credit ? MixpanelTrackEventType.PAID_BY_CREDIT : MixpanelTrackEventType.PAID_BY_DEBIT;
    if (this.shouldEventBeTracked(event, checkoutId)) {
      this.track(event, null);
      if (checkoutId && event) {
        const mixPanelSignUpStatus: any = this.getMixPanelStatus();
        mixPanelSignUpStatus[event] = checkoutId;
        this.saveMixPanelStatus(mixPanelSignUpStatus);
      }
    }
    this.trackUTMStorePurchase(utmSource);
  }

  trackUTMStorePurchase(utmSource: string) {
    switch (utmSource) {
      case "email":
        return this.trackEmailStorePurchase();
      case "facebook":
        return this.trackFacebookStorePurchase();
      case "tiktok":
        return this.trackTiktokStorePurchase();
      default:
    }
  }

  trackPayNowPurchase(utmSource = "") {
    this.track(BITS_PAY_NOW_PURCHASE);
    this.trackUTMStorePurchase(utmSource);
  }

  trackTiktokSubscriptionPurchase(signUpUUID: string | undefined) {
    if (this.shouldEventBeTracked(MixpanelTrackEventType.SIGNUP_TIKTOK_SUBSCRIPTION_PURCHASED, signUpUUID)) {
      this.trackSignUp(MixpanelTrackEventType.SIGNUP_TIKTOK_SUBSCRIPTION_PURCHASED, signUpUUID);
    }
  }

  trackFacebookSubscriptionPurchase(signUpUUID: string | undefined) {
    if (this.shouldEventBeTracked(MixpanelTrackEventType.SIGNUP_FACEBOOK_SUBSCRIPTION_PURCHASED, signUpUUID)) {
      this.trackSignUp(MixpanelTrackEventType.SIGNUP_FACEBOOK_SUBSCRIPTION_PURCHASED, signUpUUID);
    }
  }

  trackEmailSubscriptionPurchase(signUpUUID: string | undefined) {
    if (this.shouldEventBeTracked(MixpanelTrackEventType.SIGNUP_EMAIL_SUBSCRIPTION_PURCHASED, signUpUUID)) {
      this.trackSignUp(MixpanelTrackEventType.SIGNUP_EMAIL_SUBSCRIPTION_PURCHASED, signUpUUID);
    }
  }

  trackImportantInformationLinkClick(signUpUUID: string | undefined) {
    if (this.shouldEventBeTracked(MixpanelTrackEventType.SIGNUP_IMPORTANT_INFORMATION_LINK, signUpUUID)) {
      this.trackSignUp(MixpanelTrackEventType.SIGNUP_IMPORTANT_INFORMATION_LINK, signUpUUID);
    }
  }

  trackTiktokStorePurchase() {
    this.track(MixpanelTrackEventType.SIGNUP_TIKTOK_STORE_PURCHASE);
  }

  trackFacebookStorePurchase() {
    this.track(MixpanelTrackEventType.SIGNUP_FACEBOOK_STORE_PURCHASE);
  }

  trackEmailStorePurchase() {
    this.track(MixpanelTrackEventType.SIGNUP_EMAIL_STORE_PURCHASE);
  }
  trackSignUpWithDiscountSuccess() {
    this.track(MixpanelTrackEventType.SIGNUP_WITH_DISCOUNT_SUCCESS);
  }

  trackAggregatorInit(data: any) {
    this.track(MixpanelTrackEventType.AGGREGATOR_INIT, data);
  }
}
export const mixPanelInstance = new MixPanel();
