import { Col, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../SignUpFlow.scss";
import { ESIGNMessage } from "../../../../common/ESIGNMessage";
import { useSignUpEmail } from "../../../../../hooks/signup/useSignUpEmail";
import { LoginSignupSwitch } from "../../signupSwitch/LoginSignupSwitch";
import BitsIcon from "../../../common/BitsIcon";
import { EAggregators } from "utils/aggregators";
import { useEffect } from "react";
import { useContentfulContext } from "context/ContentfulContext";

const aggregatorMap: any = {};
aggregatorMap[EAggregators.TOTALLY_MONEY_STORE_CARD] = "totallymoney";
aggregatorMap[EAggregators.CLEARSCORE_STORE_CARD] = "clearscorestorecard";
aggregatorMap[EAggregators.CREDIT_KARMA_STORE_CARD] = "creditkarmastorecard";

export const SignupAggregator = ({
  aggregator,
  setSignUpStep,
  setAggregator,
}: {
  aggregator: EAggregators;
  setSignUpStep: (stepNumber: any) => any;
  setAggregator: (setAggregator: EAggregators | undefined) => void;
}): JSX.Element | null => {
  const { t, ready } = useTranslation();
  const { content, isLoading: isContentfulLoading } = useContentfulContext();
  const { email, handleEmailChange, isLoading, validated, handleSubmit, errorMessage, emailRef, hasTypo, isValidForm } =
    useSignUpEmail(setSignUpStep, aggregator);

  useEffect(() => setAggregator(aggregator), []);

  if (!ready || isContentfulLoading) {
    return null;
  }

  const renderErrorMessage = (): JSX.Element | null => {
    if (!errorMessage) {
      return null;
    }
    return (
      <div className="input-error-message" id="sign-up-contact-info-error">
        {errorMessage}
      </div>
    );
  };

  const getEmailErrorMessage = (): JSX.Element => {
    return (
      <div className="input-error-message" data-cy="signup-email-invalid">
        {hasTypo && `${t("auth.errors.invalidEmail")}`}
      </div>
    );
  };

  return (
    <>
      <div id="sign-up-email-container" className="unAuth-card">
        <BitsIcon />
        <Form
          noValidate
          validated={validated}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            return false;
          }}
        >
          <div className="signup-header">
            <h5 className="title" data-cy="signup-email-title">
              {content?.signup?.aggregator?.title ??
                content?.signup?.title ??
                "auth.forms.createAccount.titleBuildCredit"}
            </h5>
            <p className="subtitle" data-cy="signup-email-subtitle">
              {content?.signup?.aggregator?.subtitle ??
                content?.signup?.subtitle ??
                t("auth.forms.createAccount.cassetteSubtitle")}
            </p>
          </div>
          <div className="form-wrapper">
            <div className="form-group">
              <div className={errorMessage ? "input-error" : ""}>
                <input
                  ref={emailRef}
                  type="email"
                  required
                  placeholder={t("auth.forms.createAccount.emailTitle")}
                  value={email}
                  onChange={handleEmailChange}
                  id="sign-up-email"
                  className="form-input"
                />
              </div>
              {getEmailErrorMessage()}
              {renderErrorMessage()}
            </div>
            <button
              type="button"
              className={(isValidForm && "enabled") + " " + "form-input"}
              id="sign-up-email-submit"
              disabled={!isValidForm}
              onClick={handleSubmit}
            >
              {!isLoading ? `${t("auth.forms.continue")}` : <Spinner animation="border" />}
            </button>
          </div>
        </Form>
      </div>
      <Col className="login-page-switch">
        <LoginSignupSwitch />
      </Col>
      <ESIGNMessage />
    </>
  );
};
