import { useTranslation } from "react-i18next";

export const Disclaimer = () => {
  const { t, ready } = useTranslation();
  if (!ready) {
    return null;
  }
  return (
    <>
      <div className="row">
        <span className="enabled text-center">{t("common.disclaimer.paragraphOne")}</span>
      </div>
      <div className="row">
        <p className="enabled text-center">{t("common.disclaimer.paragraphTwo")}</p>
      </div>
    </>
  );
};
