import { useEffect, useState } from "react";
import * as yup from "yup";

const POTENTIAL_TYPOS: string[] = [
  ".con",
  "gnail.com",
  "gmai.com",
  ".co.ul",
  "gmail.co.uk",
  "gmal.com",
  "gmial.con",
  ".cm",
  "hotmial.co.uk",
  "hoymail.co.uk",
];

interface IUseValidateEmail {
  isValid: boolean;
  hasTypo: boolean;
}

export const useValidateEmail = (email: string): IUseValidateEmail => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [hasTypo, setHasTypo] = useState<boolean>(false);
  const validationSchema: yup.ObjectSchema<any> = yup.object().shape({
    email: yup
      .string()
      .email()
      .required()
      .test("test-typo", "There is a typo", (value: any) => {
        const hasTypo: boolean =
          POTENTIAL_TYPOS.filter((typo: string) => value.toLowerCase().endsWith(typo)).length > 0;
        setHasTypo(hasTypo);
        return hasTypo ? false : true;
      }),
  });

  useEffect(() => {
    const performValidation = async (): Promise<void> => {
      await validationSchema
        .validate({ email })
        .then(() => {
          setIsValid(true);
          setHasTypo(false);
        })
        .catch(() => {
          setIsValid(false);
        });
    };

    performValidation();
  }, [email]);

  return {
    isValid,
    hasTypo,
  };
};
