import { Link } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Disclaimer } from "components/common/Disclaimer";

export const LoginSignupSwitch = () => {
  const { pathname } = useLocation();
  const { t, ready } = useTranslation();
  if (!ready) {
    return null;
  }
  return (
    <div className="text-center">
      <ButtonGroup aria-label="Signup/Login buttons" className="btn-switch">
        {pathname === "/login" ? (
          <Link to="/signup">
            {t("auth.forms.authBarUk.haventSignedUp")} {t("auth.forms.authBarUk.signup")}
          </Link>
        ) : (
          <>
            <div className="row">
              <Disclaimer />
              <Link to="/login">
                {t("auth.forms.authBarUk.alreadySignedUp")} {t("auth.forms.authBarUk.login")}
              </Link>
            </div>
          </>
        )}
      </ButtonGroup>
    </div>
  );
};
