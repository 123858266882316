import { BitsLogo } from "../common/BitsLogo";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import {
  SIGN_UP_V2_CLEARSCORE_URL,
  SIGN_UP_V2_CREDIT_KARMA_URL,
  SIGN_UP_V2_TOTALLY_MONEY_URL,
} from "../signupFlow/signup/SignUpV2";
import { useState } from "react";

const Header = () => {
  const { t, ready } = useTranslation();
  const { pathname } = useLocation();
  const [isAggregatorUrl] = useState(
    [SIGN_UP_V2_TOTALLY_MONEY_URL, SIGN_UP_V2_CREDIT_KARMA_URL, SIGN_UP_V2_CLEARSCORE_URL].includes(pathname)
  );

  if (!ready) {
    return null;
  }

  const className = !isAggregatorUrl ? "" : " aggregators-menu";
  return (
    <header className="header-container">
      <BitsLogo to="/login" />
      <div className={`menu-items${className}`}>
        <a href="https://www.getbits.app/faqs" target="_blank">
          {t("header.faq")}
        </a>
        <Link to="/login">{t("header.signIn")}</Link>
        {!isAggregatorUrl && <Link to="/signup">{t("header.joinBits")}</Link>}
      </div>
    </header>
  );
};
export default Header;
