import { ApolloProvider } from "@apollo/client";
import { client } from "./utils/apollo";
import loadable from "@loadable/component";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { RequireAuth } from "./components/auth/RequireAuth";
import { StoreCheckout } from "./components/checkout/Checkout.types";
import { useContentfulContext } from "./context/ContentfulContext";
import ShoppingCartContextProvider from "./context/ShoppingCartContext";
import SignedInContextProvider from "./context/SignedInContext";
import { useFeatureFlag } from "./components/common/FeatureFlag";
import {
  CREATE_PASSWORD_URL,
  SIGNUP_ADDRESS_URL,
  SIGNUP_CONTACTINFO_URL,
  SIGNUP_LEGALNAME_URL,
  SIGNUP_SCHEDULE_URL,
  SIGN_UP_ABOUT_YOUR_DATA_URL,
  SIGN_UP_PAYMENT_URL,
  SIGN_UP_REFERRALS_URL,
  SUBSCRIPTION_PLANS_URL,
} from "./components/signup/SignUp";
import {
  SIGN_UP_V2_ABOUT_YOUR_DATA_URL,
  SIGN_UP_V2_CREATE_PASSWORD_URL,
  SIGN_UP_V2_INDEX_ROUTE_URL,
  SIGN_UP_V2_LEGALNAME_URL,
  SIGN_UP_V2_PAYMENT_URL,
  SIGN_UP_V2_PHONE_NUMBER_URL,
  SIGN_UP_V2_REFERRALS_URL,
  SIGN_UP_V2_SUBSCRIPTION_PLANS_URL,
  SIGN_UP_V2_VERIFY_PHONE_NUMBER_URL,
  SIGN_UP_V2_IMPORTANT_INFORMATION_URL,
  SIGN_UP_V2_CREDIT_KARMA_URL,
  SIGN_UP_V2_CLEARSCORE_URL,
  SIGN_UP_V2_TOTALLY_MONEY_URL,
} from "./components/cassetteUI/signupFlow/signup/SignUpV2";
import { GoogleTagManagerScript } from "./components/common/GoogleTagManagerScript";
import AuthService from "./components/auth/AuthService";
import { TrackAnalytic } from "./components/common/TrackAnalytic";
import { ScoreApply } from "./components/score/ScoreApply";
import { SignupLikeRoutesLayout } from "./components/cassetteUI/layout/SignupLikeRoutesLayout";
import { EAggregators } from "utils/aggregators";
import { useAggregatorTracking } from "hooks/useAggregatorTracking";
import { useQuery } from "hooks/useQuery";
import { MFA } from "components/auth/2FA";
const Clearscore = loadable(() => import(/* webpackChunkName: "Clearscore" */ "./components/aggregators/Clearscore"), {
  resolveComponent: (components) => components.Clearscore,
});

const CreditKarma = loadable(
  () => import(/* webpackChunkName: "CreditKarma" */ "./components/aggregators/CreditKarma"),
  {
    resolveComponent: (components) => components.CreditKarma,
  }
);

const TotallyMoney = loadable(
  () => import(/* webpackChunkName: "TotallyMoney" */ "./components/aggregators/TotallyMoney"),
  {
    resolveComponent: (components) => components.TotallyMoney,
  }
);

const MoneySupermarket = loadable(
  () => import(/* webpackChunkName: "MoneySupermarket" */ "./components/aggregators/MoneySupermarket"),
  {
    resolveComponent: (components) => components.MoneySupermarket,
  }
);

const Vanquis = loadable(() => import(/* webpackChunkName: "Vanquis" */ "./components/aggregators/Vanquis"), {
  resolveComponent: (components) => components.Vanquis,
});

const SignUpV2 = loadable(
  () => import(/* webpackChunkName: "SignUpV2" */ "./components/cassetteUI/signupFlow/signup/SignUpV2"),
  {
    resolveComponent: (components) => components.SignUpV2,
  }
);

const SignUp = loadable(() => import(/* webpackChunkName: "SignUp" */ "./components/signup/SignUp"), {
  resolveComponent: (components) => components.SignUp,
});

const PeriodicStatements = loadable(
  () => import(/* webpackChunkName: "PeriodicStatements" */ "./components/periodicStatements/PeriodicStatements"),
  {
    resolveComponent: (components) => components.PeriodicStatements,
  }
);

const Cart = loadable(() => import(/* webpackChunkName: "Cart" */ "./components/cart/Cart"), {
  resolveComponent: (components) => components.Cart,
});

const Orders = loadable(() => import(/* webpackChunkName: "Orders" */ "./components/store/orders/Orders"), {
  resolveComponent: (components) => components.Orders,
});

const CartTimeout = loadable(() => import(/* webpackChunkName: "CartTimeout" */ "./components/cart/CartTimeout"), {
  resolveComponent: (components) => components.CartTimeout,
});

const ShoppingCartFailure = loadable(
  () => import(/* webpackChunkName: "ShoppingCartFailure" */ "./components/checkout/cart/ShoppingCartFailure")
);

const UnauthorizedRoutesLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "UnauthorizedRoutesLayout" */ "./components/cassetteUI/layout/UnauthorizedRoutesLayout/UnauthorizedRoutesLayout"
    ),
  {
    resolveComponent: (components) => components.UnauthorizedRoutesLayout,
  }
);
const UserCookieConsent = loadable(
  () => import(/* webpackChunkName: "UserCookieConsent" */ "./components/common/UserCookieConsent"),
  {
    resolveComponent: (components) => components.UserCookieConsent,
  }
);

const VideoList = loadable(() => import(/* webpackChunkName: "VideoList" */ "./components/video/VideoList"), {
  resolveComponent: (components) => components.VideoList,
});

const Layout = loadable(() => import(/* webpackChunkName: "Layout" */ "./components/cassetteUI/layout/Layout"), {
  resolveComponent: (components) => components.Layout,
});

const Logout = loadable(() => import(/* webpackChunkName: "Logout" */ "./components/auth/Logout"), {
  resolveComponent: (components) => components.Logout,
});

const ResetPassword = loadable(
  () =>
    import(/* webpackChunkName: "ResetPassword" */ "./components/cassetteUI/signupFlow/forgotPassword/ResetPassword")
);

const ChangePassword = loadable(
  () => import(/* webpackChunkName: "ChangePassword" */ "./components/auth/change-password/ResetPassword")
);

const Error404 = loadable(() => import(/* webpackChunkName: "Error404" */ "./components/cassetteUI/error/Error404"), {
  resolveComponent: (components) => components.Error404,
});

const NotFound = loadable(
  () => import(/* webpackChunkName: "NotFound" */ "./components/cassetteUI/signupFlow/notFound/NotFound"),
  {
    resolveComponent: (components) => components.NotFound,
  }
);

const Settings = loadable(
  () => import(/* webpackChunkName: "Settings" */ "./components/cassettePages/settings/Settings"),
  {
    resolveComponent: (components) => components.Settings,
  }
);

const Home = loadable(() => import(/* webpackChunkName: "Home" */ "./components/cassettePages/home/Home"), {
  resolveComponent: (components) => components.Home,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RentReporting = loadable(
  () => import(/* webpackChunkName: "RentReporting" */ "./components/cassettePages/rentReporting/RentReporting"),
  {
    resolveComponent: (components) => components.RentReporting,
  }
);

const ScoreSimulator = loadable(
  () => import(/* webpackChunkName: "ScoreSimulator" */ "./components/cassettePages/scoreSimulator/ScoreSimulator"),
  {
    resolveComponent: (components) => components.ScoreSimulator,
  }
);

const Education = loadable(
  () => import(/* webpackChunkName: "Education" */ "./components/cassettePages/education/Education"),
  {
    resolveComponent: (components) => components.Education,
  }
);

const Store = loadable(() => import(/* webpackChunkName: "Store" */ "./components/cassetteUI/store/Store"), {
  resolveComponent: (components) => components.Store,
});

const Checkout = loadable(() => import(/* webpackChunkName: "ProductList" */ "./components/checkout/Checkout"), {
  resolveComponent: (components) => components.Checkout,
});

const ProductList = loadable(
  () => import(/* webpackChunkName: "ProductList" */ "./components/cassetteUI/store/prodcut-list/ProductList"),
  {
    resolveComponent: (components) => components.ProductList,
  }
);

const ProductDetails = loadable(
  () => import(/* webpackChunkName: "ProductDetails" */ "./components/cassetteUI/store/product-details/ProductDetails"),
  {
    resolveComponent: (components) => components.ProductDetails,
  }
);

const OurImpact = loadable(() => import(/* webpackChunkName: "OurImpact" */ "./components/ourImpact/OurImpact"));

const PaymentFailed = loadable(
  () => import(/* webpackChunkName: "PaymentFailed" */ "./components/store/upcoming-payments/PaymentFailed")
);

const AllStoreCategories = loadable(
  () => import(/* webpackChunkName: "AllStoreCategories" */ "./components/cassetteUI/store/AllStoreCategories"),
  {
    resolveComponent: (components) => components.AllStoreCategories,
  }
);

const Login = loadable(
  () => import(/* webpackChunkName: "Login" */ "./components/cassetteUI/signupFlow/signin/Login"),
  {
    resolveComponent: (components) => components.Login,
  }
);

const Membership = loadable(
  () => import(/* webpackChunkName: "Membership" */ "./components/cassettePages/membership/Membership"),
  {
    resolveComponent: (components) => components.Membership,
  }
);
const SignUpDiscount = loadable(
  () => import(/* webpackChunkName: "SignUpDiscount" */ "./components/signup/signup-discount/SignUpDiscount"),
  {
    resolveComponent: (components) => components.SignUpDiscount,
  }
);
const Marketplace = loadable(
  () => import(/* webpackChunkName: "Marketplace" */ "./components/cassettePages/marketplace/Marketplace"),
  {
    resolveComponent: (components) => components.Marketplace,
  }
);

interface IUseApp {
  headerClass: string | null;
  wrapperClass: string | null;
  bypassAuthCheck: boolean;
  displayBackButton: boolean;
  setHeaderClass: (headerClass: string | null) => void;
  setBypassAuthCheck: (bypassAuthCheck: boolean) => void;
  setWrapperClass: (wrapperClass: string | null) => void;
  setDisplayBackButton: (displayBackButton: boolean) => void;
  backButtonRoute: string | undefined;
  checkout: StoreCheckout | undefined;
  signUpRoute: JSX.Element | undefined;
  contentReady: boolean;
  isLoading: boolean;
}

export const SIGN_UP_FLOW_V2_ACTIVE_KEY = "signupFlowV2";

const useApp = (): IUseApp => {
  const [headerClass, setHeaderClass] = useState<string | null>(null);
  const [bypassAuthCheck, setBypassAuthCheck] = useState<boolean>(false);
  const [wrapperClass, setWrapperClass] = useState<string | null>(null);
  const [displayBackButton, setDisplayBackButton] = useState<boolean>(false);
  const [backButtonRoute, setBackButtonRoute] = useState<string | undefined>(undefined);
  const { pathname } = useLocation();
  const [checkout] = useState<StoreCheckout>();
  const [signUpRoute, setSignUpRoute] = useState<JSX.Element | undefined>();
  const { contentReady, content } = useContentfulContext();
  const isSignUpFlowV2Active = useFeatureFlag(SIGN_UP_FLOW_V2_ACTIVE_KEY);
  const navigate = useNavigate();
  const { displayImportantInformationPage }: any = content ?? { displayImportantInformationPage: false };
  const [aggregator, setAggregator] = useState<EAggregators | undefined>();
  const aggregatorMap: any = {};
  aggregatorMap[SIGN_UP_V2_TOTALLY_MONEY_URL] = EAggregators.TOTALLY_MONEY_STORE_CARD;
  aggregatorMap[SIGN_UP_V2_CREDIT_KARMA_URL] = EAggregators.CREDIT_KARMA_STORE_CARD;
  const { trackInit } = useAggregatorTracking();
  const [isLoading, setIsLoading] = useState(true);
  const aggregatorUid = useQuery().get("uid") ?? undefined;

  useEffect(() => {
    if (![SIGN_UP_V2_TOTALLY_MONEY_URL, SIGN_UP_V2_CLEARSCORE_URL, SIGN_UP_V2_CREDIT_KARMA_URL].includes(pathname)) {
      setIsLoading(false);
      return;
    }
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile =
      /windows phone/i.test(userAgent) ||
      /android/i.test(userAgent) ||
      //@ts-ignore
      (/iPhone|iPod|iPad/.test(userAgent) && !window.MSStream);
    trackInit(aggregatorMap[pathname], aggregatorUid)
      .then((link: string) => {
        if (isMobile) {
          window.location.href = link;
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(isMobile));
  }, []);

  const handleWebViewTokenChange = () => {
    const token: string = window?.bitsAccessToken ?? "";
    const route: string = window?.webViewRequestedRoute ?? "";
    if (token) {
      AuthService.saveAuthToken(token);

      if (route) {
        navigate(route);
      } else {
        navigate("/dashboard");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("bitsAccessToken", handleWebViewTokenChange);
    if (window?.bitsAccessToken) {
      handleWebViewTokenChange();
    }
    return () => window.removeEventListener("bitsAccessToken", handleWebViewTokenChange);
  }, []);

  const getAggregatorRoute = () => {
    switch (aggregator) {
      case EAggregators.TOTALLY_MONEY:
        return SIGN_UP_V2_TOTALLY_MONEY_URL;
      case EAggregators.CLEARSCORE:
        return SIGN_UP_V2_CLEARSCORE_URL;
      case EAggregators.CREDIT_KARMA:
        return SIGN_UP_V2_CREDIT_KARMA_URL;
      default:
        return SIGN_UP_V2_INDEX_ROUTE_URL;
    }
  };
  const getBackButtonRouteV2 = (): string | undefined => {
    switch (pathname.toLowerCase()) {
      case SIGN_UP_V2_CREATE_PASSWORD_URL:
        return SIGN_UP_V2_PHONE_NUMBER_URL;
      case SIGN_UP_V2_VERIFY_PHONE_NUMBER_URL:
        return SIGN_UP_V2_VERIFY_PHONE_NUMBER_URL;
      case SIGN_UP_V2_LEGALNAME_URL:
        return SIGN_UP_V2_SUBSCRIPTION_PLANS_URL;
      case SIGN_UP_V2_PHONE_NUMBER_URL:
        return aggregator !== undefined ? getAggregatorRoute() : SIGN_UP_V2_INDEX_ROUTE_URL;
      case SIGN_UP_V2_REFERRALS_URL:
        return SIGN_UP_V2_LEGALNAME_URL;
      case SIGN_UP_V2_IMPORTANT_INFORMATION_URL:
        return SIGN_UP_V2_REFERRALS_URL;
      case SIGN_UP_V2_ABOUT_YOUR_DATA_URL:
        return displayImportantInformationPage ? SIGN_UP_V2_IMPORTANT_INFORMATION_URL : SIGN_UP_V2_REFERRALS_URL;
      case SIGN_UP_V2_PAYMENT_URL:
        return SIGN_UP_V2_ABOUT_YOUR_DATA_URL;

      default:
        return undefined;
    }
  };

  const getBackButtonRouteV1 = (): string | undefined => {
    switch (pathname.toLowerCase()) {
      case SIGNUP_ADDRESS_URL:
        return SIGNUP_LEGALNAME_URL;
      case SIGNUP_CONTACTINFO_URL:
        return SIGNUP_ADDRESS_URL;
      case CREATE_PASSWORD_URL:
        return SIGNUP_CONTACTINFO_URL;
      case SIGN_UP_REFERRALS_URL:
        return CREATE_PASSWORD_URL;
      case SIGN_UP_ABOUT_YOUR_DATA_URL:
        return SIGN_UP_REFERRALS_URL;
      case SUBSCRIPTION_PLANS_URL:
        return SIGN_UP_ABOUT_YOUR_DATA_URL;
      case SIGN_UP_PAYMENT_URL:
        return SUBSCRIPTION_PLANS_URL;
      case SIGNUP_SCHEDULE_URL:
        return SUBSCRIPTION_PLANS_URL;
      default:
        return undefined;
    }
  };

  const getBackButtonRoute = () => (isSignUpFlowV2Active ? getBackButtonRouteV2() : getBackButtonRouteV1());

  const getSignUpRoute = () => {
    return isSignUpFlowV2Active ? (
      <SignUpV2
        setHeaderClass={setHeaderClass}
        setWrapperClass={setWrapperClass}
        setBypassAuthCheck={setBypassAuthCheck}
        setDisplayBackButton={setDisplayBackButton}
        setAggregator={setAggregator}
      />
    ) : (
      <SignUp
        setHeaderClass={setHeaderClass}
        setWrapperClass={setWrapperClass}
        setBypassAuthCheck={setBypassAuthCheck}
        setDisplayBackButton={setDisplayBackButton}
      />
    );
  };

  useEffect(() => {
    setBackButtonRoute(getBackButtonRoute());
  }, [pathname]);

  useEffect(() => {
    if (contentReady) {
      setSignUpRoute(getSignUpRoute());
    }
  }, [isSignUpFlowV2Active, contentReady]);

  return {
    headerClass,
    bypassAuthCheck,
    setHeaderClass,
    setBypassAuthCheck,
    wrapperClass,
    setWrapperClass,
    displayBackButton,
    setDisplayBackButton,
    backButtonRoute,
    checkout,
    signUpRoute,
    contentReady: contentReady ?? false,
    isLoading,
  };
};

export const CassetteAppWrapper = () => {
  const {
    headerClass,
    wrapperClass,
    bypassAuthCheck,
    displayBackButton,
    backButtonRoute,
    signUpRoute,
    contentReady,
    isLoading,
  } = useApp();
  if (!contentReady || isLoading) {
    return null;
  }
  return (
    <>
      <GoogleTagManagerScript />
      <SignedInContextProvider>
        <ShoppingCartContextProvider>
          <Routes>
            <Route path="clearscore" element={<Clearscore />} />
            <Route path="creditkarma" element={<CreditKarma />} />
            <Route path="totallymoney" element={<TotallyMoney />} />
            <Route path="moneysupermarket" element={<MoneySupermarket />} />
            <Route path="vanquis" element={<Vanquis />} />
            <Route
              element={
                <RequireAuth>
                  <ApolloProvider client={client}>
                    <Layout />
                  </ApolloProvider>
                </RequireAuth>
              }
            >
              <Route path="/settings/*" element={<Settings />} />
              <Route path="/simulator" element={<ScoreSimulator />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/membership" element={<Membership />} />
              <Route path="/education" element={<Education />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/store">
                <Route path="categories" element={<AllStoreCategories />} />
                <Route path="category/:slug" element={<ProductList />} />
                <Route path="product/:id/:slug" element={<ProductDetails />} />
                <Route path="orders/*" element={<Orders />} />
                <Route index element={<Store />} />
              </Route>
              <Route path="/checkout/*" element={<Checkout />} />
              <Route path="/ourimpact/*" element={<OurImpact />} />
              <Route
                path="/video"
                element={<VideoList playlistId={process.env.REACT_APP_YOUTUBE_PLAYLIST_ID ?? ""} />}
              />
              <Route path="/paymentError" element={<PaymentFailed />} />
              <Route path="/statements" element={<PeriodicStatements />} />
              <Route path="/store/cart" element={<Cart />} />
              <Route path="/store/cart/error" element={<ShoppingCartFailure />} />
              <Route path="/store/timeout" element={<CartTimeout />} />
            </Route>
            <Route
              element={
                <RequireAuth>
                  <SignupLikeRoutesLayout
                    headerClass={headerClass}
                    wrapperClass={wrapperClass}
                    displayBackButton={displayBackButton}
                    backButtonRoute={backButtonRoute}
                  ></SignupLikeRoutesLayout>
                </RequireAuth>
              }
            >
              <Route path="/score-apply/*" element={<ScoreApply />} />
            </Route>
            <Route
              path="/"
              element={
                <UnauthorizedRoutesLayout
                  headerClass={headerClass}
                  wrapperClass={wrapperClass}
                  bypassAuthCheck={bypassAuthCheck}
                  displayBackButton={displayBackButton}
                  backButtonRoute={backButtonRoute}
                />
              }
            >
              <Route path="login" element={<Login />} />
              <Route path="smsverification" element={<MFA />} />
              <Route path="forgotPassword" element={<ResetPassword />} />
              <Route path="signup/*" element={signUpRoute} />
              <Route path="changepassword" element={<ChangePassword />} />
              <Route path="bits-discount/plans/:token/*" element={<SignUpDiscount />} />
              <Route index element={signUpRoute} />
              {!AuthService.isAuthenticated() && <Route path="*" element={<NotFound />} />}
            </Route>
            <Route path="logout" element={<Logout />} />
            <Route
              path="*"
              element={
                <ApolloProvider client={client}>
                  <Error404 />
                </ApolloProvider>
              }
            />
          </Routes>
        </ShoppingCartContextProvider>
        <TrackAnalytic />
      </SignedInContextProvider>
      <UserCookieConsent />
    </>
  );
};
